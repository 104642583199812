<template>
<b-overlay :show="loading">
  <AuthWrapper>
    <template #card>
      <div class="text-center">
        <div>
          <p>Fill the form below to get started</p>
        </div>
      </div>
      <div class="px-3">
        <validation-observer ref="registerForm" #default="{invalid}">
          <b-form @submit.prevent="onSignup">
            <b-form-group label="Email (optional)" label-for="email">
              <validation-provider #default="{ errors }" name="Email" rules="email" vid="email">
                <div class="exxtra-input">
                  <span class="leading">
                    <feather-icon icon="MailIcon" size="24" />
                  </span>
                  <input id="login-username" v-model="registration.email" type="email" name="register-email" placeholder="" />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Phone" label-for="phone">
              <template #label>
                <span>Phone<sup style="color: tomato">*</sup></span>
              </template>
  
              <validation-provider #default="{ errors }" name="Phone" rules="required|length:10" vid="phone">
                <div class="exxtra-input">
                  <span class="leading">
                    <feather-icon icon="PhoneIcon" size="24" />
                  </span>
                  <input id="phone" v-model="registration.phone" name="register-phone" placeholder="" />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Password" label-for="password">
              <template #label>
                <span>Password<sup style="color: tomato">*</sup></span>
              </template>

              <validation-provider #default="{ errors }" name="Password" rules="required|password">
                <div class="exxtra-input">
                  <span class="leading">
                    <feather-icon icon="LockIcon" size="24" />
                  </span>
                  <input id="login-password" v-model="registration.password" :type="password1FieldType" placeholder="············">
                  <span class="trailing">
                    <feather-icon class="cursor-pointer" :icon="password1ToggleIcon" @click="togglePassword1Visibility" />
                  </span>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Confirm Password" label-for="confirm-password">
              <template #label>
                <span>Confirm Password<sup style="color: tomato">*</sup></span>
              </template>

              <validation-provider #default="{ errors }" name="Confirm Password" rules="required|confirmed:Password">
                <div class="exxtra-input">
                  <span class="leading">
                    <feather-icon icon="LockIcon" size="24" />
                  </span>
                  <input id="confirm-password" v-model="Cpassword" :type="password2FieldType" placeholder="············">
                  <span class="trailing">
                    <feather-icon class="cursor-pointer" :icon="password2ToggleIcon" @click="togglePassword2Visibility" />
                  </span>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="my-1">
              <validation-provider #default="{ errors }" name="Please agree to the terms before proceeding" rules="required|terms-and-conditions">
                <b-form-checkbox id="register-privacy-policy" v-model="agree_to_terms" name="checkbox">
                  <small>
                    Signing up signifies that you have read and agree
                    to the <b-link :to="{ name: 'terms' }" target="_blank" style="text-decoration: underline">Terms of Service</b-link>
                    and our <b-link :to="{ name: 'privacy' }" target="_blank" style="text-decoration: underline">
                    Privacy Policy.</b-link>
                  </small>
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div>
              <b-button class="py-1" type="submit" variant="primary" block :disabled="invalid || !agree_to_terms">
                Sign Up
              </b-button>
            </div>

            <div class="text-center">
              <p class="pt-2"> Already have an account?
                <span>
                  <b-link :to="{ name:'auth-login' }">
                    <span>Sign In</span>
                  </b-link>
                </span>
              </p>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </template>
  </AuthWrapper>
</b-overlay>
</template>

<script>
import { get } from "lodash"
import { email, required } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import AppLogo from '@core/layouts/components/Logo.vue';
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import vSelect from 'vue-select'

import {
  BAlert,
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay
} from 'bootstrap-vue'

export default {
  components: {
    AuthWrapper,
    BOverlay,
    BAlert,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormSelect,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BFormFile,
    BFormCheckbox,
    BInputGroupAppend,
    // validations
    AppLogo,
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      errorMessage: '',

      registration: {
        email: '',
        phone: '',
        password: '',
        account_type: 'member',
      },
      agree_to_terms: false,

      Cpassword: '',

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',

      // validation rules
      required,
      email
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    async onSignup() {
      try {
        this.loading = true;
        this.errorMessage = '';

        const success = await this.$refs.registerForm.validate();
        if (!success) {
          return;
        }

        const formData = new FormData();

        Object.keys(this.registration)
          .forEach(field_name => {
            formData.append(field_name, this.registration[field_name]);
          });

        await this.useJwt()
          .authService
          .register(formData);

        this.$router.replace({
          name: "auth-register-success"
        }).catch(() => {})
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        if (error_message.includes("An account with this email already exists.")) {
          this.$refs.registerForm.setErrors({
            email: [error_message]
          });
        }

        if (error_message.includes("An account with this phone number already exists.")) {
          this.$refs.registerForm.setErrors({
            phone: [error_message]
          });
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
